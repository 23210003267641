@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -16px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 16px; /* gutter size */
  background-clip: padding-box;
}

@keyframes flip {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }
  50% {
    transform: perspective(1000px) rotateY(180deg);
  }
  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}

.image-container {
  perspective: 1000px;
}

.image-flip {
  animation: flip 1s linear;
  transform-style: preserve-3d;
  backface-visibility: visible;
}

.image-flip:nth-child(odd) {
  animation-delay: 5s;
}

/* Add this new rule for debugging */
.debug-flip {
  border: 5px solid red !important;
}